<template>
  <div class="main">
    <div>
      <Panel module="Operacional" title="Painel de OS" layout="large" :showFilter="false" :showSearch="false"
        :showButtons="false">
        <div slot="content-main">
          <br />
          <OsPanel />
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import OsPanel from "../../../components/modules/operational/OsPanel.vue";
import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";

export default {
  name: "OsPanelView",
  components: {
    OsPanel,
    Panel,
  },
};
</script>

<style scoped>
.main {
  margin-left: 120px;
  margin-top: 100px;
  margin: auto;
}
</style>