<template>
  <div>
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <div class="showAll">
            <CheckboxSimple fieldTarget="showAll" title="Mostrar todas" v-model="showAll" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <InputText title="Pesquisar (Enter)" :disabled="disabled" field="search" :maxLength="50" v-model="search"
            :enter="getAll" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <DateTime title="Período" :disabled="isShowAll" fieldTarget="period" format="DD/MM/YYYY" type="date"
            :range="true" :confirm="true" confirmText="Filtrar" placeholder :required="false"
            v-model="selectedPeriod" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <div>
            <SelectOption :showButtonNext="false" :disabled="disabled || isShowAll" :initialValue="initialPeriod"
              fieldTarget="month" size="small" :width="120" :height="40" :options="options" v-model="period" />
          </div>
        </b-col>
      </b-row>
    </Molded>
    <div class="div-filter-active" v-if="isFilterActive">
      Filtro Ativo
      <span class="icon-close" @click="removeFilter()">
        <i class="icon-tag fas fa-times-circle"></i>
      </span>
    </div>
    <br />
    <div>
      <HorizontalFilter :showTotalPerPage="false">
        <div class="fixed-filter-options div-filter" slot="content-filter-horizontal">
          <div>
            <Toggle title="Saídas" :color="'#009183'" v-model="showDelivery" :changed="changedFilter" />
            <Toggle title="Retornos" :color="'#3D4EAE'" v-model="showDevolution" :changed="changedFilter" />
            <!--   <div class="text-right">
              <CheckboxSimple title="Mostrar realizadas" :markFormDirty="false" v-model="showDone"
                :changed="changedFilter" />
            </div> -->
          </div>
        </div>
      </HorizontalFilter>
      <TableTotalRecords :totalRecords="data.length" />
      <div class="div-legend text-center">
        <div class="side-by-side box-calendar delivery-delay"></div>
        <div class="side-by-side title magin">Saídas em atraso</div>
        <div class="side-by-side box-calendar delivery"></div>
        <div class="side-by-side title magin">Saídas em dia</div>
        <div class="side-by-side box-calendar devolution-delay"></div>
        <div class="side-by-side title magin">Retornos em atraso</div>
        <div class="side-by-side box-calendar devolution"></div>
        <div class="side-by-side title magin">Retornos em dia</div>
        <div class="side-by-side box-calendar rent"></div>
      </div>
    </div>
    <div class="div-timer text-center">
      <Timer :initialTimer="60" :endTime="endTime" />
    </div>
    <Loading type="line" :center="false" v-show="loading" />
    <div>
      <div class="side-by-side" v-for="item in data">
        <div class="box-panel">
          <div class="delivery-devolution text-center">
            <div class="side-by-side box-calendar-large" :style="'background-color:' + item.backgroundColor"></div>
            <span class="margin" v-if="item.type == 'delivery'">
              <span> <span v-if="item.isDelivery"></span> <span class="title">Saída</span></span>
            </span>
            <span class="margin title" v-else>Retorno</span>
            <span class="title">Nº{{ item.numberRent }}</span>
          </div>
          <div class="start-end text-center">
            <span class="title margin" v-if="item.type == 'delivery'">
              {{ item.startStr }}
            </span>
            <span class="title margin" v-else>
              {{ item.endStr }}
            </span>
          </div>
          <div class="title text-center">
            <span v-if="item.identification" class="identification">
              {{ item.identification }}
            </span>
            <span class="no-identification" v-else>Sem identificação</span>
          </div>
          <div class="title div-company text-center">
            <i class="fa-solid fa-building"></i>
            <span class="company">{{ item.companyName }}</span>
            <br />
            <i class="fa-solid fa-user"></i>
            <span class="company">{{ item.contactName }}</span>
          </div>
          <div class="div-btn text-center">
            <div class="side-by-side">
              <ButtonMovimentStock :totalization="item.totalization" :showTitleButton="false"
                :keyButton="item.keyButton" :rentId="item.rentId" :periodRentId="item.periodRentId" />
            </div>
            <div class="side-by-side">
              <Button :_key="item.keyButton + 'eye'" tooltip="Visualizar" type="primary" classIcon="fa-solid fa-eye"
                size="small" :clicked="clickedEvent" :params="item" />
            </div>
            <div class="side-by-side">
              <Button :_key="item.keyButton + 'edit'" tooltip="Editar" color="white" backGroundColor="#A9A9A9"
                classIcon="fa-solid fa-pen-to-square" size="small" :clicked="edit" :params="item" />
            </div>
            <div class="side-by-side">
              <Button :_key="item.keyButton + 'share'" tooltip="Compartilhar" type="info"
                classIcon="fa-sharp fa-regular fa-share-nodes" size="small" :clicked="printRent" :params="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal :title="titleModal" :width="widthModal" :height="750" v-if="showModal('infoOsPanel')"
      :onHideModal="closeModal">
      <OperationRent module="Operational" :rentId="rentId" :periodRentId="periodRentId" v-if="operational" />
    </Modal>
    <PrintPreview v-if="print" module="Operational" :rentId="rentId" :periodRentId="periodRentId"
      :onHideModal="closeModal" />
  </div>
</template>
<script>

import ButtonMovimentStock from "./ButtonMovimentStock.vue";
import Timer from "@nixweb/nixloc-ui/src/component/shared/Timer";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import HorizontalFilter from "@nixweb/nixloc-ui/src/component/shared/HorizontalFilter.vue";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import OperationRent from "../../../components/modules/operational/OperationRent.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import SelectOption from "@nixweb/nixloc-ui/src/component/shared/SelectOption.vue";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import PrintPreview from '../adm/document/PrintPreview.vue'
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";

import { mapActions, mapMutations, mapGetters, mapState } from "vuex";

export default {
  name: "OsPanel",
  components: {
    Button,
    Timer,
    HorizontalFilter,
    Toggle,
    CheckboxSimple,
    OperationRent,
    Modal,
    Loading,
    InputText,
    ButtonMovimentStock,
    SelectOption,
    DateTime,
    TableTotalRecords, PrintPreview, Molded
  },
  data() {
    return {
      filter: this.$route.params.filter,
      urlGet: "/api/v1/operational/get-all-os-panel",
      data: [],
      loading: true,
      showDelivery: true,
      showDevolution: true,
      showDone: false,
      titleModal: "",
      widthModal: 900,
      showPreview: false,
      documentId: "",
      rentId: "",
      periodRentId: "",
      selectedPeriod: [],
      initialPeriod: "today",
      period: "today",
      showAll: false,
      search: "",
      print: false,
      operational: false,
      options: [
        {
          title: "Em atraso",
          titleSize: "13",
          icon: "fa-sharp fa-regular fa-sensor-triangle-exclamation",
          value: "delayed",
        },
        {
          title: "Hoje",
          titleSize: "13",
          icon: "fa-solid fa-calendar-week",
          value: "today",
        },
        {
          title: "Semana",
          titleSize: "13",
          icon: "fa-solid fa-calendar-day",
          value: "week",
        },
        {
          title: "Mês",
          titleSize: "13",
          icon: "fa-solid fa-calendar-days",
          value: "month",
        },
        {
          title: "Ano",
          titleSize: "13",
          icon: "fa-solid fa-calendar",
          value: "year",
        },
      ],
    };
  },
  created() {
    if (this.filter) {
      this.period = this.filter;
      this.initialPeriod = this.filter;
    }

    this.showAll = this.filterStorage.showAll;
    this.selectedPeriod = this.filterStorage.selectedPeriod;
    this.initialPeriod = this.filterStorage.period;
    this.period = this.filterStorage.period;
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("generateMoviment", ["filterStorage"]),
    disabled() {
      if (this.selectedPeriod.length > 0) return true;
      return false;
    },
    isShowAll() {
      if (this.showAll) return true;
      return false;
    },
    isFilterActive() {
      if (this.period != "today") return true;
      if (this.selectedPeriod.length > 0) return true;
      if (this.showAll) return true;

      return false;
    }
  },
  methods: {
    ...mapMutations("generic", ["openModal", "addEvent", "removeLoading"]),
    ...mapMutations("generateMoviment", ["addFilterStorage"]),
    ...mapActions("generic", ["getApi"]),
    getAll() {
      this.loading = true;
      this.data = [];
      let params = {
        url: this.urlGet,
        obj: {
          showDelivery: this.showDelivery,
          showDevolution: this.showDevolution,
          search: this.search,
          done: this.showDone,
          period: this.period,
          showAll: this.showAll,
          start: this.selectedPeriod[0],
          end: this.selectedPeriod[1]
        },
      };

      this.addFilterStorage({ showAll: this.showAll, selectedPeriod: this.selectedPeriod, period: this.period });

      this.getApi(params).then((response) => {
        this.data = response.content;
        let self = this;
        setTimeout(function () {
          self.loading = false;
          self.removeLoading(["panel"]);
        }, 500);
      });
    },
    changedFilter() {
      this.getAll();
    },
    endTime() {
      if (!this.loading) {
        this.getAll();
      }
    },
    nextStep() {
      this.showPreview = true;
      this.widthModal = 1200;
    },
    back() {
      this.showPreview = false;
      this.widthModal = 900;
      this.removeLoading(["back"]);
    },
    clickedEvent(params) {
      this.titleModal = `Locação Nº ${params.numberRent}`;
      this.operational = true;
      this.rentId = params.rentId;
      this.periodRentId = params.periodRentId;
      this.openModal("infoOsPanel");
      this.addEvent({
        name: "clickedEventCalendar",
        data: { rentId: this.rentId, periodRentId: this.periodRentId },
      });
      this.removeLoading([params.keyButton + 'eye']);
    },
    printRent(params) {
      this.print = true;
      this.rentId = params.rentId;
      this.periodRentId = params.periodRentId;
      this.removeLoading([params.keyButton + 'share']);
    },
    closeModal() {
      this.print = false;
      this.operational = false;
    },
    removeFilter() {
      this.selectedPeriod = [];
      this.initialPeriod = "today";
      this.period = "today";
      this.showAll = false;
      this.search = "";
    },
    edit(params) {
      let self = this;
      setTimeout(function () {
        self.$router.push({
          name: "rentOperationalUpdate",
          params: { id: params.rentId, periodRentId: params.periodRentId }
        });
        self.removeLoading([params.keyButton + 'edit']);
      }, 200);

    }
  },
  watch: {
    period: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    showAll: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    selectedPeriod: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    search: {
      handler() {
        if (this.search.length == 0)
          this.getAll();
      },
      deep: true,
    },
    event: {
      handler(event) {
        if (event.name == "emailSent") {
          this.print = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.box-panel {
  margin-right: 15px;
  margin-bottom: 15px;
  width: 360px;
  min-height: 180px;
  padding: 10px;
  border: 1px solid #dbdfe9;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 3%);
}

.delivery-devolution {
  font-size: 20px;
}

.div-filter {
  margin-bottom: 30px;
  padding: 10px;
}

.margin {
  margin-right: 10px;
}

.start-end {
  font-size: 20px;
  font-weight: bold;
}

.identification {
  font-size: 16px;
  font-weight: 500;
}

.div-company {
  margin-top: 20px;
  margin-bottom: 20px;
}

.company {
  margin-left: 5px;
  font-size: 14px;
}

.box-calendar {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 3px;
}

.box-calendar-large {
  width: 12px;
  height: 12px;
  border-radius: 20px;
  margin-right: 5px;
}

.div-legend {
  margin-top: 15px;
  margin-bottom: 15px;
}

.delivery-delay {
  background-color: red;
}

.delivery {
  background-color: #009183;
}

.devolution {
  background-color: #3d4eae;
}

.devolution-delay {
  background-color: #ff8a1b;
}

.magin {
  margin-right: 15px;
}

.div-timer {
  margin-bottom: 20px;
}

.div-btn {
  margin-bottom: 10px;
}

.no-identification {
  font-size: 12px;
  color: darkgray;
  font-style: italic;
}

.div-filter-active {
  margin-left: 13px;
  font-size: 12px;
  background-color: #F1BC31;
  color: white;
  margin-top: 5px;
  padding-left: 7px;
  padding-right: 5px;
  border-radius: 15px;
  width: 110px;
  cursor: pointer !important;
}

.icon-close {
  font-size: 15px;
  cursor: pointer !important;
}
</style>